import {css} from "@emotion/react";
import {BorderBox} from "./BorderBox";
import {Alert, AlertIcon, Button, Checkbox, Heading} from "@chakra-ui/react";
import {InputForm} from "./InputForm";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {BasePage} from "./BasePage";
import {HalfSection, SectionContainer} from "./SectionContainer";
import {HR} from "./HR";
import {CollectibleComponent} from "./CollectibleComponent";
import {State, useHookstate} from "@hookstate/core";
import {getUserPaymentInfos, updatePaymentInfos} from "../net-utils";
import {ExternalError, PaymentInfo} from "../types";
import {
  validateAccount,
  validateBankId,
  validateHB,
  validateNotEmpty,
  validatePostalNumber,
  validateSSN
} from "../validations";
import {GoButton} from "./GoButton";
import {DefaultSpinner} from "./DefaultSpinner";
import {globalUser} from "../App";

export function BankSignupPage() {
  const location = useLocation()
  const locationState = location.state as LocationState | undefined
  const username = locationState?.username
  const navigate = useNavigate();
  const elements = useHookstate<PaymentInfo[]>([])
  useEffect(() => {
    if (username == null) {
      navigate('/')
    }
  }, [])
  if (!username) {
    return null
  }
  return <BankInputPage username={username} elements={elements}/>
}

export function BankUpdatePage() {
  const username = useParams<{ username: string }>().username
  const navigate = useNavigate();
  const elements = useHookstate<PaymentInfo[] | null>(null)
  useEffect(() => {
    if (!username) {
      navigate('/')
      return
    }
    (async () => {
      const response = await getUserPaymentInfos(username)
      elements.set(response)
    })()
  }, [username]);

  if (!username) {
    return null
  }
  const rawElements = elements.ornull
  if (!rawElements) {
    return <DefaultSpinner/>
  }
  return <BankInputPage username={username} elements={rawElements}/>
}

function BankInputPage(props: {username: string, elements: State<PaymentInfo[]>}) {
  const {username} = props
  const elements = useHookstate(props.elements);
  const navigate = useNavigate();
  const error = useHookstate<string | null>(null);
  return <BasePage>
    <BorderBox css={css`display: flex;
      flex-direction: column;
      gap: 8px;`}>
      <Alert status={'info'}>
        <AlertIcon/>
        Hér er hægt að vista upplýsingar fyrir rafræna reikninga sem nýtist þeim er vinna í verktöku.
        Með rafrænum reikningum Heka sparar þú tíma og auðveldar innheimtu fyrir unnin verk.
        Rafrænir reikningar Heka vistast á mínum síðum. Þessum upplýsingum er alltaf hægt að breyta.
      </Alert>
      <CollectibleComponent<PaymentInfo>
        display={(state, index) =>
          <PaymentInputs index={index} paymentInfo={state}/>}
        elements={elements} construct={() => createBlankPaymentInfo()}
        addMoreText={'Bæta við öðrum seljanda'}
        lineSeparator={true}
      />
      {error.get() && <Alert status={'error'}>
          <AlertIcon/>
        {error.get()}
      </Alert>}
      <div css={css`margin-top: 10px; display: flex; gap: 8px`}>
        <GoButton onClick={async () => {
          if (elements.some(e => !validatePaymentInfo(e.get()))) {
            error.set('Reikningsupplýsingar vantar eða eru ekki á réttu formi')
            return
          }
          const response = await updatePaymentInfos(username, elements.get());
          if (!response.ok) {
            const errorResponse = await response.json() as ExternalError
            error.set(errorResponse.error ?? "Óþekkt villa")
            return;
          }
          navigate(`/users/${username}`)
        }} submitError={error}>Áfram</GoButton>
        <Link to={`/users/${username}`}>
          <Button>Sleppa</Button>
        </Link>
      </div>
    </BorderBox>
  </BasePage>;
}

interface LocationState {
  isDoctor: boolean,
  username: string,
}

const PaymentInputs = (props: {index: number, paymentInfo: State<PaymentInfo>}) => {
  const user = useHookstate(globalUser)
  const userIsDoctor = user.get()?.user?.preferences?.professions.map(p => p.type).includes('Doctor')
  const paymentInfo = useHookstate(props.paymentInfo)
  return <div css={css`width: 100%; display: flex; flex-direction: column; gap: 6px`}>
    <Heading size={'md'} css={css`margin-bottom: 8px`}>Greiðsluupplýsingar {props.index !== 0 && `#${props.index+1}`}</Heading>
    <SectionContainer>
      <HalfSection>
        <InputForm label={"Kennitala"} type={'number'} state={paymentInfo.ssn} validate={validateSSN}/>
        <InputForm label={"Nafn"} state={paymentInfo.name} validate={validateNotEmpty}/>
      </HalfSection>
      <HalfSection>
        <InputForm label={"Heimilisfang"} state={paymentInfo.homeAddress} validate={validateNotEmpty}/>
        <InputForm label={"Póstnúmer"} type={'number'} state={paymentInfo.postalNumber} validate={validatePostalNumber}/>
      </HalfSection>
    </SectionContainer>
    {userIsDoctor && <Checkbox size={'lg'} css={css`margin-top: 8px`} onChange={
      e => paymentInfo.isCompany.set(e.target.checked)
    } isChecked={paymentInfo.isCompany.get()}>
        Fyrirtæki
    </Checkbox>}
    <HR/>
    {!userIsDoctor && <><SectionContainer>
      <HalfSection>
        <InputForm label={"Launaflokkur"} state={paymentInfo.salaryClass}/>
      </HalfSection>
      <HalfSection/>
    </SectionContainer>
    <HR/>
    </>}
    <Heading fontSize={"xl"} css={css`margin-bottom: 8px; margin-top: 12px`}>Reikningsupplýsingar {props.index !== 0 && `#${props.index+1}`}</Heading>
    <div css={css`display: flex; gap: 4px`}>
      <InputForm label={"Banki"} type={"number"} css={css`flex: 1`} state={paymentInfo.bankId} validate={validateBankId}/>
      <InputForm label={"Höfuðbók"} type={"number"} css={css`flex: 1`} state={paymentInfo.hb} validate={validateHB}/>
      <InputForm label={"Reikningsnúmer"} type={"number"} css={css`flex: 4`} state={paymentInfo.account} validate={validateAccount}/>
    </div>
  </div>;
};

const createBlankPaymentInfo = (): PaymentInfo => {
  return {
    ssn: "",
    homeAddress: "",
    name: "",
    postalNumber: "",
    isCompany: false,
    salaryClass: "",
    bankId: "",
    hb: "",
    account: ""
  }
}

const validatePaymentInfo = (paymentInfo: PaymentInfo): boolean => {
  return paymentInfo.ssn.length !== 0 && validateSSN(paymentInfo.ssn) == null
    && paymentInfo.homeAddress.length !== 0
    && paymentInfo.name.length !== 0
    && paymentInfo.postalNumber.length !== 0 && validatePostalNumber(paymentInfo.postalNumber) == null
    && paymentInfo.bankId.length !== 0 && validateBankId(paymentInfo.bankId) == null
    && paymentInfo.hb.length !== 0 && validateHB(paymentInfo.hb) == null
    && paymentInfo.account.length !== 0 && validateAccount(paymentInfo.account) == null;
}

