export const validateEmail = (input: string) =>
  input.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/) ? null : 'Ógilt netfang'
export const validatePhone = (input: string) =>
  input.match(/^\+?[0-9]{6,15}$/) ? null : 'Ógilt símanúmer'
export const validate7DigitPhone = (input: string) =>
  input.match(/^[0-9]{7}$/) ? null : 'Símanúmer verður að vera 7 tölustafir'
export const validateSSN = (ssn: string): string | null =>
  ssn.match(/^\d{10}$/) ? null : "Kennitala má aðeins vera 10 tölustafir";
export const validatePostalNumber = (postalNumber: string): string | null =>
  postalNumber.match(/^\d{3}$/) ? null : "Póstnúmer má aðeins vera 3 tölustafir";
export const validateBankId = (bankId: string): string | null =>
  bankId.match(/^\d{3,4}$/) ? null : "Banki má aðeins vera 3 til 4 tölustafir";
export const validateHB = (hb: string): string | null =>
  hb.match(/^\d{2}$/) ? null : "Höfuðbók má aðeins vera 2 tölustafir";
export const validateAccount = (account: string): string | null =>
  account.match(/^\d+$/) ? null : "Reikningsnúmer má aðeins vera tölustafir";
export const validateNotEmpty = (value: string): string | null =>
  value.length === 0 ? "Þessi dálkur má ekki vera tómur" : null;
export const validateNotZeroOrNegative = (value: string): string | null => value === "NaN" ? "Þessi dálkur má ekki vera tómur" :
  parseInt(value) <= 0 ? "Þessi dálkur má ekki vera 0 eða neikvæð tala" : null;