import {
  Clinic,
  ClinicMapMarker,
  Contract,
  ContractInformation,
  CreatePositionRequest,
  ExistingUserSignIn,
  Institution, NewUserElectronicId,
  NotificationMessage,
  PaymentInfo,
  Position,
  PositionRequest,
  SignupUserResponse,
  User,
  UserPreferences,
  UserResponse
} from "./types";
import {getUserJwt, getUsername} from "./heka-utils";
import { FilterOptions } from "./components/HomePage";
import { WorkerClass } from "./worker-class";
import { Invoices } from "./components/InvoicePage";
import { DynamicShiftType } from "./components/CreatePositionPage/ShiftCreationModal";
import {ManualSpecialistInfo} from "./components/ContractOutsiderPage";

export const baseUrl = process.env.REACT_APP_BASE_URL;

export class NetworkError extends Error {
  constructor(message: string, public response: Response) {
    super(message);
  }
}

async function throwIfNotOk(response: Response) {
  if (!response.ok) {
    const body = await response.json()
    const error = body.error
    if (error) {
      // window.alert(`Villa kom upp: ${body.error}`)
      if (response.status === 401 && error === 'User not verified') {
        window.location.replace('/verification-pending')
      }
      else if (response.status === 401) {
        localStorage.setItem("redirectAfterLogin", window.location.pathname)
        window.location.replace('/')
      }
      else if (response.status === 404) {
        window.location.replace('/not-found')
      }
      throw new NetworkError(error, response);
    }
    throw new NetworkError(response.statusText, response);  }
}

export const dateTimeReviver = function (key: string, value: any) {
  if (typeof value === 'string') {
    // iso string regex
    const a = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/.exec(value);
    if (a) {
      return new Date(value.replace(/\[.*?]/, ''));
    }
  }
  return value;
}

function jwt() {
  return `Bearer ${getUserJwt()}`;
}

export const createUser = async (user: UserPreferences, electronicId: string, certificationJwt: string): Promise<SignupUserResponse> => {
  const response = await fetch(`${baseUrl}/api/v1/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'heka-electronic-id': electronicId,
      'heka-certification-id': certificationJwt,
    },
    body: JSON.stringify(user),
    credentials: 'same-origin'
  });
  await throwIfNotOk(response);
  return await response.json() as SignupUserResponse
};

export const updateUser = async (username: string, user: UserPreferences) => {
  const response = await fetch(`${baseUrl}/api/v1/users/${username}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt(),
    },
    body: JSON.stringify(user),
    credentials: 'same-origin'
  });
  await throwIfNotOk(response);
}

export const getUser = async (username: string): Promise<UserResponse> => {
  const response = await fetch(`${baseUrl}/api/v1/users/${username}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt(),
    },
    credentials: 'same-origin'
  })
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as UserResponse
}

export const getUserPaymentInfos = async (username: string): Promise<PaymentInfo[]> => {
  const response = await fetch(`${baseUrl}/api/v1/users/${username}/payment-infos`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt(),
    },
    credentials: 'same-origin'
  })
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as PaymentInfo[]
}

export const updatePaymentInfos = async (username: string, paymentInfos: PaymentInfo[]) =>
  await fetch(`${baseUrl}/api/v1/users/${username}/payment-infos`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt(),
    },
    body: JSON.stringify(paymentInfos),
    credentials: 'same-origin'
  })

export const requestElectronicId = async (phoneNumber: string): Promise<Response> => {
  return await fetch(`${baseUrl}/api/v1/electronic-ids/request`, {
    method: 'POST',
    headers: {
      'heka-phone-number': phoneNumber
    }
  })
}

export const subscribeToNewsletter = async (email: string) => {
  return await fetch(`${baseUrl}/api/v1/emails/subscribe`, {
    method: 'POST',
    headers: {
      'heka-email': email,
      'Accept': 'application/json'
    }
  })
}

export const uploadFile = async (file: File, credentials: FileAuthCredentials) => {
  const multipartFormData = new FormData();
  multipartFormData.append('file', file);
  const response = await fetch(`${baseUrl}/api/v1/files`, {
    method: 'POST',
    body: multipartFormData,
    headers: {
      [credentials.header]: credentials.value
    }
  })
  await throwIfNotOk(response)
  return response
}

export const createInstitution = async (institution: Institution) => {
  const response = await fetch(`${baseUrl}/api/v1/institutions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt(),
    },
    body: JSON.stringify(institution),
  })
  await throwIfNotOk(response)
  return response
}

export const updateInstitution = async (institution: Institution) => {
  const institutionId = institution.institutionId;
  if (!institutionId) throw new Error('institutionId is missing in updateInstitution');
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application',
      'Authorization': jwt(),
    },
    body: JSON.stringify(institution),
  })
  await throwIfNotOk(response)
  return response
}

export const getInstitutions = async (): Promise<Institution[]> => {
  const response = await fetch(`${baseUrl}/api/v1/institutions`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application',
      'Authorization': jwt(),
    },
  })
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as Institution[]
}

export interface FileAuthCredentials {
  header: string;
  value: string;
}

export const getInstitutionById = async (institutionId: string): Promise<Institution> => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  })
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as Institution
}

export const createPosition = async (position: Position) => {
  const response = await fetch(`${baseUrl}/api/v1/positions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt(),
    },
    body: JSON.stringify(position),
  })
  await throwIfNotOk(response)
  return response
}

export const getPositionById = async (positionId: string): Promise<Position> => {
  const response = await fetch(`${baseUrl}/api/v1/positions/${positionId}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  })
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as Position
}

export const getClinicById = async (institutionId: string, clinicId: string): Promise<Clinic> => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/clinics/${clinicId}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  })
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as Clinic
}

export const getPositionsForClinic = async (institutionId: string, clinicId: string, currentOnly: boolean = false) => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/clinics/${clinicId}/positions?currentOnly=${currentOnly}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt(),
    }
  })
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as Position[]
}

export const updatePosition = async (position: Position) => {
  const response = await fetch(`${baseUrl}/api/v1/positions/${position._id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt(),
    },
    body: JSON.stringify(position),
  })
  await throwIfNotOk(response)
  return response
}

export const getPositions = async (filterOptions?: FilterOptions): Promise<Position[]> => {
  const queryParams = filterOptions && new URLSearchParams({
    institutionIds: filterOptions.institutionIds.join(","),
    clinicIds: filterOptions.clinicIds.join(","),
    workerClassRequirements: filterOptions.workerClassRequirements.join(","),
    htypes: filterOptions.htypes.join(","),
    beginDate: filterOptions.beginDate?.toISOString() ?? '',
    endDate: filterOptions.endDate?.toISOString() ?? '',
    sortBy: filterOptions.sortBy,
    professionTypes: filterOptions.professionTypes.join(","),
  })
  const response = await fetch(`${baseUrl}/api/v1/positions?${queryParams}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application',
      'Authorization': jwt(),
    }
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as Position[]
}

export const getClinicMapMarkers = async (): Promise<ClinicMapMarker[]> => {
  const response = await fetch(`${baseUrl}/api/v1/positions/clinic-map-markers`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt(),
    }
  });
  await throwIfNotOk(response)
  return await response.json() as ClinicMapMarker[]
}

export const createPositionRequest = async (positionRequest: CreatePositionRequest) => {
  const response = await fetch(`${baseUrl}/api/v1/positions/requests`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt(),
    },
    body: JSON.stringify(positionRequest),
  })
  await throwIfNotOk(response)
}

export const updatePositionRequest = async (id: string, positionRequest: CreatePositionRequest) => {
  const response = await fetch(`${baseUrl}/api/v1/positions/requests/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt(),
    },
    body: JSON.stringify(positionRequest),
  })
  await throwIfNotOk(response)
}

export const deletePositionRequest = async (positionRequestId: string) => {
  const response = await fetch(`${baseUrl}/api/v1/positions/requests/${positionRequestId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt(),
    },
  })
  await throwIfNotOk(response)
}

export const getPositionRequestById = async (positionRequestId: string): Promise<PositionRequest> => {
  const response = await fetch(`${baseUrl}/api/v1/positions/requests/${positionRequestId}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as PositionRequest
}

export const deletePosition = async (positionId: string) => {
  const response = await fetch(`${baseUrl}/api/v1/positions/${positionId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt(),
    },
  })
  await throwIfNotOk(response)
}

export const getPositionRequestsByPositionId = async (positionId: string): Promise<PositionRequest[]> => {
  const response = await fetch(`${baseUrl}/api/v1/positions/${positionId}/requests`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as PositionRequest[]
}

export const acceptPositionRequest = async (positionRequestId: string) => {
  const response = await fetch(`${baseUrl}/api/v1/positions/requests/${positionRequestId}/accept`, {
    method: 'PUT',
    headers: {
      'Authorization': jwt(),
    }
  });
  await throwIfNotOk(response)
}

export const rejectPositionRequest = async (positionRequestId: string, message: string) => {
  const response = await fetch(`${baseUrl}/api/v1/positions/requests/${positionRequestId}/reject`, {
    method: 'PUT',
    headers: {
      'Authorization': jwt(),
    },
    body: JSON.stringify({ message })
  });
  await throwIfNotOk(response)
}

export const getContractInformation = async (positionRequestId: string): Promise<ContractInformation> => {
  const response = await fetch(`${baseUrl}/api/v1/contracts/${positionRequestId}/information`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as ContractInformation
}

export const createClinic = async (institutionId: string, clinic: Clinic) => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/clinics`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt(),
    },
    body: JSON.stringify(clinic),
  })
  await throwIfNotOk(response)
}

export const updateClinic = async (institutionId: string, clinicId: string, clinic: Clinic) => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/clinics/${clinicId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt(),
    },
    body: JSON.stringify(clinic),
  })
  await throwIfNotOk(response)
}

export const deleteClinic = async (institutionId: string, clinicId: string) => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/clinics/${clinicId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt(),
    }
  })
  await throwIfNotOk(response)
}

export interface ExportContract {
  contractTemplate: string, contractValues: string[], positionRequestId: string
}

export type CreateContract = ExportContract & {
  institutionSignerSSN: string,
  institutionSignerEmail: string
}

export const downloadContractPdf = async (createContract: ExportContract) => {
  const response = await fetch(`${baseUrl}/api/v1/contracts/export`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/pdf',
      'Authorization': jwt(),
    },
    body: JSON.stringify(createContract),
  })
  await throwIfNotOk(response)
  const data = await response.blob()
  const file = window.URL.createObjectURL(data)
  window.open(file, '_blank')
}

export const downloadContractPdfByContractId = async (contractId: string) => {
  const response = await fetch(`${baseUrl}/api/v1/contracts/${contractId}/export`, {
    method: 'GET',
    headers: {
      'Accept': 'application/pdf',
      'Authorization': jwt(),
    },
  })
  await throwIfNotOk(response)
  const data = await response.blob()
  const file = window.URL.createObjectURL(data)
  window.open(file, '_blank')
}

export const createContract = async (createContract: CreateContract) => {
  const response = await fetch(`${baseUrl}/api/v1/contracts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt(),
    },
    body: JSON.stringify(createContract),
  });
  await throwIfNotOk(response)
}

export const getContractById = async (contractId: string): Promise<Contract> => {
  const response = await fetch(`${baseUrl}/api/v1/contracts/${contractId}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as Contract
}

export const signDocument = async (contractId: string, phoneNumber: string) => {
  const response = await fetch(`${baseUrl}/api/v1/contracts/${contractId}/sign`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': jwt(),
    },
    body: JSON.stringify({ phoneNumber }),
  });
  await throwIfNotOk(response)
}

export interface ContractSignStatus {
  institutionSigned: boolean,
  employeeSigned: boolean,
}

export const getContractSignStatus = async (contractId: string): Promise<ContractSignStatus> => {
  const response = await fetch(`${baseUrl}/api/v1/contracts/${contractId}/sign/status`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt(),
    }
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as ContractSignStatus
}

export interface ContractListItem {
  contract: Contract,
  institutionName: string,
  clinicName: string,
  beginDate: Date,
  endDate: Date,
}

export const getContractsForUser = async (): Promise<ContractListItem[]> => {
  const response = await fetch(`${baseUrl}/api/v1/contracts/user`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    },
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as ContractListItem[]
}

export const getEmployeesForInstitutionId = async (institutionId: string): Promise<User[]> => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/employees`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    },
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as User[]
}

export const getPositionsForUser = async (username: string): Promise<Position[]> => {
  const response = await fetch(`${baseUrl}/api/v1/positions/users/${username}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    },
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as Position[]
}

export const cancelPosition = async (positionId: string, reason: string) => {
  const response = await fetch(`${baseUrl}/api/v1/positions/${positionId}/cancel`, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    },
    body: JSON.stringify({ reason }),
  });
  await throwIfNotOk(response)
}

export const addFavoritePosition = async (username: string, positionId: string) => {
  const response = await fetch(`${baseUrl}/api/v1/users/${username}/favorite-positions/${positionId}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    },
  });
  await throwIfNotOk(response)
}

export const removeFavoritePosition = async (username: string, positionId: string) => {
  const response = await fetch(`${baseUrl}/api/v1/users/${username}/favorite-positions/${positionId}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
}

export interface FavoritePosition {
  positionId: string,
  username: string,
  beginDate: Date,
  endDate: Date,
  clinicName: string,
  professionType: WorkerClass.ProfessionType,
  created: Date,
  _id: string,
}

export const getFavoritePositionsForUser = async (username: string): Promise<FavoritePosition[]> => {
  const response = await fetch(`${baseUrl}/api/v1/users/${username}/favorite-positions`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  })
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as FavoritePosition[]
}

export interface UserFreeDate {
  fromDate: Date,
  toDate: Date,
  username: string,
  _id?: string,
}

export const addUserFreeDate = async (userFreeDate: UserFreeDate) => {
  const response = await fetch(`${baseUrl}/api/v1/users/${userFreeDate.username}/free-dates`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    },
    body: JSON.stringify(userFreeDate),
  });
  await throwIfNotOk(response)
}

export const deleteUserFreeDate = async (username: string, userFreeDateId: string) => {
  const response = await fetch(`${baseUrl}/api/v1/users/${username}/free-dates/${userFreeDateId}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
}

export const getNotificationMessages = async (username: string, limit: number = 4): Promise<NotificationMessage[]> => {
  const response = await fetch(`${baseUrl}/api/v1/users/${username}/notification-messages?limit=${limit}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    },
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as NotificationMessage[]
}

export const updateLastCheckedNotifications = async (username: string) => {
  const response = await fetch(`${baseUrl}/api/v1/users/${username}/notification-messages/last-checked`, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
}

export const countUnreadNotificationMessages = async (username: string): Promise<{unread: number}> => {
  const response = await fetch(`${baseUrl}/api/v1/users/${username}/notification-messages/count-unread`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text()) as {unread: number}
}

export interface PositionAndRequest {
  position: Position,
  request: PositionRequest,
}

export const getPositionsAndRequestsForUser = async (username: string): Promise<PositionAndRequest[]> => {
  const response = await fetch(`${baseUrl}/api/v1/users/${username}/positions-and-requests`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    },
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as PositionAndRequest[]
}

export const getPositionsForInstitution = async (institutionId: string): Promise<Position[]> => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/positions`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as Position[]
}

export const duplicatePosition = async (positionId: string): Promise<string> => {
  const response = await fetch(`${baseUrl}/api/v1/positions/${positionId}/duplicate`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
  const newPositionId = await response.text();
  return newPositionId
}

export const createAdminInvite = async (email: string, institutionId: string) => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/admin-invites/${email}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
}

export const createInstitutionAdminUser = async (phoneNumber: string, jwt: string): Promise<string> => {
  const response = await fetch(`${baseUrl}/api/v1/users/institution-admin`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'heka-jwt': jwt,
      'heka-phone-number': phoneNumber,
    }
  });
  await throwIfNotOk(response)
  const userjwt = await response.json() as SignupUserResponse;
  return userjwt.jwt
}

export interface PositionTemplate {
  _id?: string,
  name: string,
  createdBy: string,
  position: Position,
}

export const createPositionTemplate = async (name: string, position: Position, institutionId: string) => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/position-templates`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    },
    body: JSON.stringify({name, position}),
  });
  await throwIfNotOk(response)
}

export const getPositionTemplates = async (institutionId: string): Promise<PositionTemplate[]> => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/position-templates`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as PositionTemplate[]
}

export const deletePositionTemplate = async (positionTemplateId: string, institutionId: string) => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/position-templates/${positionTemplateId}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
}

export const getInvoiceTemplateForPositionId = async (positionId: string): Promise<Invoices.Invoice> => {
  const response = await fetch(`${baseUrl}/api/v1/invoices/template/${positionId}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    },
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as Invoices.Invoice
}

export const createInvoice = async (template: Invoices.Invoice) => {
  const response = await fetch(`${baseUrl}/api/v1/invoices`, {
    method: 'POST',
    body: JSON.stringify(template),
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
}

export const invalidateInvoice = async (positionId: string) => {
  const response = await fetch(`${baseUrl}/api/v1/invoices/${positionId}/invalidate`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
}

export const getInvoicesForUser = async (username: string): Promise<Invoices.Invoice[]> => {
  const response = await fetch(`${baseUrl}/api/v1/users/${username}/invoices`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as Invoices.Invoice[]
}

// "/{institutionId}/dynamic-shift-types" bind Method.POST
export const createDynamicShiftType = async (institutionId: string, dynamicShiftType: DynamicShiftType) => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/dynamic-shift-types`, {
    method: 'POST',
    body: JSON.stringify(dynamicShiftType),
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
}

// "/{institutionId}/dynamic-shift-types" bind Method.PUT
export const updateDynamicShiftType = async (institutionId: string, dynamicShiftType: DynamicShiftType) => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/dynamic-shift-types`, {
    method: 'PUT',
    body: JSON.stringify(dynamicShiftType),
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
}

// "/{institutionId}/dynamic-shift-types/{shiftTypeId}" bind Method.DELETE
export const deleteDynamicShiftType = async (institutionId: string, shiftTypeId: string) => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/dynamic-shift-types/${shiftTypeId}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
}

export const refreshUserJwt = async (): Promise<string> => {
  const response = await fetch(`${baseUrl}/api/v1/users/refresh`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
  const userjwt = await response.json() as SignupUserResponse;
  return userjwt.jwt
}

// "/{username}/verify" bind Method.PUT
export const verifyUser = async (username: string) => {
  const response = await fetch(`${baseUrl}/api/v1/users/${username}/verify`, {
    method: 'PUT',
    headers: {
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
}

export interface PositionCalendarItem {
  beginDate: Date,
  endDate: Date,
  positionId: string,
  accepted: boolean,
  professionType: string,
  requestCount: number
}

export const getCalendarItems = async (institutionId: string, clinicId: string): Promise<PositionCalendarItem[]> => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/clinics/${clinicId}/positions/calendar`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
  return JSON.parse(await response.text(), dateTimeReviver) as PositionCalendarItem[]
}

// "/{institutionId}/positions/{positionId}/reopen" bind Method.PUT to reopenAcceptedPosition()
export const reopenAcceptedPosition = async (institutionId: string, positionId: string) => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/positions/${positionId}/reopen`, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
}

export interface ManualRegistrationRequest {
  ssn: string,
  phoneNumber: string,
  password: string,
}

// "/manual-registration" bind Method.POST to requestManualRegistration(),
export const requestManualRegistration = async (manualRegistrationRequest: ManualRegistrationRequest) => {
  const response = await fetch(`${baseUrl}/api/v1/electronic-ids/manual-registration`, {
    method: 'POST',
    body: JSON.stringify(manualRegistrationRequest),
    headers: {
      'Accept': 'application/json',
    }
  });
  await throwIfNotOk(response)
  return await response.json() as NewUserElectronicId;
}

export interface ManualLoginRequest {
  ssn: string,
  password: string,
}

export const processManualLogin = async (manualLoginRequest: ManualLoginRequest): Promise<ExistingUserSignIn> => {
  const response = await fetch(`${baseUrl}/api/v1/electronic-ids/manual-login`, {
    method: 'POST',
    body: JSON.stringify(manualLoginRequest),
    headers: {
      'Accept': 'application/json',
    }
  });
  await throwIfNotOk(response)
  return await response.json() as ExistingUserSignIn
}

export interface UnlayerEmailTemplate {
  html: string,
  plainText: string,
  subject: string,
}

export interface SendTestEmailBody {
  email: string,
  template: UnlayerEmailTemplate
}

export const sendTestEmail = async (sendTestEmailBody: SendTestEmailBody) => {
  const response = await fetch(`${baseUrl}/api/v1/emails/send-test`, {
    method: 'POST',
    body: JSON.stringify(sendTestEmailBody),
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
}

export interface RecipientFilters {
  doctors: boolean,
  nurses: boolean,
  midwives: boolean,
  managers: boolean
}

export interface BroadcastBody {
  filters: RecipientFilters,
  template: UnlayerEmailTemplate
}

export const sendBroadcastEmail = async (broadcastBody: BroadcastBody) => {
  const response = await fetch(`${baseUrl}/api/v1/emails/send`, {
    method: 'POST',
    body: JSON.stringify(broadcastBody),
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
}

// "/search" bind Method.GET to searchByNameOrSSN()
export const searchUsersByNameOrSSN = async (searchString: string): Promise<User[]> => {
  const response = await fetch(`${baseUrl}/api/v1/users/search?query=${searchString}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response);
  return JSON.parse(await response.text(), dateTimeReviver) as User[]
}

// "/{institutionId}/outsider-users" bind Method.POST to createOutsiderUser()
export const createOutsiderUser = async (institutionId: string, user: ManualSpecialistInfo) => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/outsider-users`, {
    method: 'POST',
    body: JSON.stringify(user),
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
  return await response.json() as User
}

// "/{institutionId}/outsider-positions" bind Method.POST to createOutsiderPosition()
export const createOutsiderPosition = async (institutionId: string, employeeUsername: string, position: Position): Promise<string> => {
  const response = await fetch(`${baseUrl}/api/v1/institutions/${institutionId}/outsider-positions`, {
    method: 'POST',
    body: JSON.stringify({
      position,
      employeeUsername
    }),
    headers: {
      'Accept': 'application/json',
      'Authorization': jwt()
    }
  });
  await throwIfNotOk(response)
  return (await response.text()).replaceAll('"', '')
}