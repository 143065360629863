import {State} from "@hookstate/core";
import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps
} from "@chakra-ui/react";
import {css} from "@emotion/react";

export function ModalBase(props: {
  open: State<boolean>,
  title: string,
  children: React.ReactNode
} & Omit<ModalProps, 'isOpen' | 'onClose'>) {
  const {open, title, children, ...rest} = props;
  const close = () => open.set(false);
  return <Modal isOpen={open.get()} onClose={close} {...rest}>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton/>
      <ModalBody css={css`display: flex; flex-direction: column; gap: 16px; padding-bottom: 20px`}>
        {children}
      </ModalBody>
    </ModalContent>
  </Modal>
}