import {State, useHookstate} from "@hookstate/core";
import React from "react";
import {Button, ButtonProps, Spinner} from "@chakra-ui/react";
import {css} from "@emotion/react";

export function GoButton(props: {
  onClick: () => Promise<void>,
  submitError: State<string | null>,
  children: React.ReactNode,
  colorScheme?: string
} & ButtonProps) {
  const isRunning = useHookstate(false);
  const {onClick, children, colorScheme, submitError, leftIcon, isDisabled, ...rest} = props;
  return <div css={css`min-width: 100px`}><Button colorScheme={colorScheme ?? 'pink'} onClick={async () => {
    isRunning.set(true)
    submitError.set(null);
    try {
      await props.onClick()
    } catch (e) {
      if (e instanceof Error) {
        console.error(e, e.stack)
        submitError.set(e.message)
      } else {
        submitError.set('Óþekkt villa')
      }
    }
    isRunning.set(false)
  }} isDisabled={isRunning.get() ? true : isDisabled} leftIcon={
    isRunning.get() ? <Spinner size={'sm'}/> : leftIcon
  } {...rest}>{props.children}</Button></div>
}