import React, {useEffect, useState} from 'react';
import {BasePage} from './BasePage';
import {BorderBox} from './BorderBox';
import {css} from '@emotion/react';
import {
  Alert, AlertIcon,
  Badge,
  Button,
  Checkbox,
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Select,
  Text,
  Textarea
} from '@chakra-ui/react';
import {addDays, differenceInDays, format, isSameDay} from 'date-fns';
import {
  calculatePayForPosition,
  cloneState,
  DATE_FMT, findAppropriatePayment,
  formatCurrency, formatDate,
  getDayNameShort, getHighestWorkerClass,
  getSupplementalPay,
  getUsername
} from '../heka-utils';
import {useNavigate, useParams} from 'react-router-dom';
import {LabelInfo} from './LabelInfo';
import {CreatePositionRequest, Institution, PaymentInfo, Position, ShiftTypeName} from '../types';
import {FaFacebook, FaFacebookMessenger, FaTwitter} from 'react-icons/fa';
import {CheckIcon, EmailIcon} from '@chakra-ui/icons';
import {State, useHookstate} from '@hookstate/core';
import {FavoriteIcon} from './FavoriteIcon';
import {
  createPositionRequest,
  deletePositionRequest, FavoritePosition, getFavoritePositionsForUser,
  getInstitutionById,
  getPositionById, getUserPaymentInfos,
  updatePositionRequest
} from "../net-utils";
import {DefaultSpinner} from "./DefaultSpinner";
import {useTranslation} from 'react-i18next';
import {BiTrash} from "react-icons/bi";
import {globalFavoritePositions} from "./NavigationBar";
import {globalUser} from "../App";
import {PositionAdminControls} from "./ClinicPage";
import {GoButton} from "./GoButton";
import AlertDisplay from "./AlertDisplay";
import _ from "lodash";
import {groupShiftTimes} from "../shift-time-range";
import {Helmet} from "react-helmet";

// Get full URL of page with https, hostname, etc.
const getUrl = (positionId: string) => `${window.location.protocol}//${window.location.host}/positions/${positionId}`

function ShareButtons(props: {positionId: string, title: string}) {
  const url = encodeURIComponent(getUrl(props.positionId));
  return <>
    <Heading fontSize={"lg"}>Deila</Heading>
    <BorderBox css={css`display: inline-flex;
            gap: 8px;
            width: fit-content;
            flex-wrap: wrap`}>
      <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${url}&amp;src=sdkpreparse`}>
        <Button colorScheme={"facebook"} leftIcon={<FaFacebook/>}>
          Facebook
        </Button>
      </a>
      <a href={`https://www.facebook.com/dialog/send?link=${url}&redirect_uri=${url}&app_id=1735066596909708`} target='_blank'>
        <Button colorScheme={"messenger"} leftIcon={<FaFacebookMessenger/>}>
          Messenger
        </Button>
      </a>
      <a href={`https://twitter.com/intent/tweet?text=${url}`} target="_blank">
        <Button colorScheme={"twitter"} leftIcon={<FaTwitter/>}>
          Twitter
        </Button>
      </a>
      <a href={`mailto:?subject=${props.title}&body=${url}`}>
        <Button colorScheme={"gray"} leftIcon={<EmailIcon/>}>
          Senda í tölvupóst
        </Button>
      </a>
    </BorderBox></>;
}

export const PositionPage = () => {
  const params = useParams()
  const navigate = useNavigate()
  const positionId = params['positionId']
  if (!positionId) throw new Error('Missing positionId')
  const positionState = useHookstate<Position | null>(null)
  const favoritePositions = useHookstate(globalFavoritePositions).get()
  useEffect(() => {
    (async () => {
      const position = await getPositionById(positionId);
      positionState.set(position)
    })();
  }, [positionId]);
  const position = positionState.get()
  const isApplyModalOpen = useHookstate(false);
  const user = useHookstate(globalUser).get()
  if (!position) return <DefaultSpinner/>
  const beginDate = position.beginDate!!
  const endDate = position.endDate!!
  const title = `Heka - ${position.institutionName}, ${position.clinicName} - ${formatDate(position.beginDate!!)} - ${formatDate(position.endDate!!)}`;
  return <>
    <BasePage>
      <Helmet>
        <div id="fb-root"></div>
        <script async defer crossOrigin="anonymous"
          src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v17.0&appId=1735066596909708&autoLogAppEvents=1"
          nonce="45IOuwDA"></script>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={position.description} />
        <meta property="og:image" content={position.clinicImagePath} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="400" />
      </Helmet>
      <div css={css`display: flex;
        flex-direction: column;
        gap: 8px`}>
        {user?.institutions?.map(i => i.id)?.includes(position.institutionId) && <div css={css`display: flex;
          flex-direction: column;
          gap: 8px`}>
          {position.cancelled && <Alert status={'error'}>
            <AlertIcon/>
            <Text>Verktakinn sem samþykkti þessa umsókn hefur merkt að hann hafi hætt við. <br/>
              Við ráðleggum þér að endurbirta stöðuna eða fara yfir hinar umsókirnar.
            </Text>
          </Alert>}
          {!position.isPublic && <AlertDisplay status={"warning"} error={'Þessi staða er ekki sýnileg notendum. Ýttu á "Birta auglýsingu" til þess að gera hana sýnilega.'}/>}
          <BorderBox>
            <PositionAdminControls hideShow={true} position={position}
              onDelete={() => navigate(`/institutions/${position.institutionId}`)}
              refresh={() => window.location.reload()}/>
          </BorderBox>
        </div>}
        <BorderBox css={css`display: flex;
          flex-wrap: wrap;
          gap: 16px`}>
          <div css={css`flex: 1;
            display: flex;
            flex-direction: column`}>
            <Heading size={'md'}
              css={css`margin-bottom: 8px`}>{position.institutionName}</Heading>
            <div css={css`display: flex;
              flex-direction: column;
              gap: 4px`}>
              <LabelInfo label={'Starfsstöð'} value={position.clinicName}/>
              <Divider/>
              <LabelInfo label={'Heilbrigðisstétt'} value={'Læknir'}/>
              <Divider/>
              <LabelInfo label={'Sérgrein'} value={position.specialization}/>
              <Divider/>
              <LabelInfo label={'Tímabil'}>
                <Badge>{format(beginDate, DATE_FMT)}</Badge>
                -
                <Badge>{format(endDate, DATE_FMT)}</Badge>
                <Badge></Badge>
              </LabelInfo>
              <Divider/>
              <div>
                <Badge colorScheme={'purple'}>{position.htype}</Badge>
              </div>
            </div>
            <div css={css`margin-top: auto`}>
            </div>
            <div css={css`margin-top: auto;
              display: flex;
              flex-direction: column;
              gap: 8px`}>
              <FavoriteIcon css={css`margin-left: 0`} showText={true} positionId={positionId} isEnabled={
                favoritePositions?.some(favoritePosition => favoritePosition.positionId === positionId) ?? false
              }/>
              <Button colorScheme={'green'} onClick={() => isApplyModalOpen.set(true)}>{
                !position.userRequest ? 'Sækja um' : 'Breyta umsókn'
              }</Button>
            </div>
          </div>
          <div css={css`flex: 2`}>
            <img src={position.clinicImagePath}
              css={css`object-fit: cover;
                width: 100%;
                border-radius: 8px`}/>
          </div>
        </BorderBox>
        <div css={css`display: flex;
          gap: 8px; flex-wrap: wrap;`}>
          <LeftSection position={position}/>
          <RightSection position={position}/>
        </div>
        <ShareButtons positionId={position._id!!} title={title}/>
      </div>
    </BasePage>
    <ApplyModal open={isApplyModalOpen} position={positionState}/>
  </>;
};

const LeftSection = (props: {position: Position}) => {
  const position = props.position;
  const shifts = position.shifts
  const {t} = useTranslation()
  const dynamicShiftTypes = props.position.dynamicShiftTypes
  const getDynamicShiftByName = (name: string) => dynamicShiftTypes.find(dynamicShiftType => dynamicShiftType.shiftName === name)
  const dateComponents = shifts.map(shift => <div>
    <div css={css`display: flex;
      gap: 4px;
      align-items: center; `}>
      <Heading fontSize={'md'}>{getDayNameShort(shift.date)}</Heading>
      <Text fontSize={'md'}>{format(shift.date, DATE_FMT)}</Text>
    </div>
    <div css={css`display: flex; flex-direction: column`}>
      {shift.types.map(shiftType => <div><Badge colorScheme={getDynamicShiftByName(shiftType)?.color}>{shiftType}</Badge></div>)}
    </div>
  </div>)
  return <div css={css`flex: 2;
    display: flex;
    flex-direction: column;
    gap: 8px`}>
    {position.description.length ? <><Heading fontSize={'lg'}>Lýsing</Heading>
      <BorderBox>
        <Text css={css`white-space: pre-wrap;`}>{position.description}</Text>
      </BorderBox>
      <Heading fontSize={'lg'}>Hæfniskröfur</Heading></> : null}
    <BorderBox css={css`display: flex; gap: 4px`}>
      {position.workerClassRequirements.map(workerClass => <Badge>
          {t(`${position.professionType}.${workerClass}`)}
      </Badge>)}
    </BorderBox>
    <Heading fontSize={'lg'}>Vaktir í boði</Heading>
    <BorderBox>
      <div css={css`display: flex; gap: 8px; flex-wrap: wrap`}>
        {dateComponents}
      </div>
      <Divider css={css`margin-bottom: 12px; margin-top: 12px`}/>
      <div css={css`display: flex; gap: 4px; flex-direction: column; flex-wrap: wrap`}>
        {
          dynamicShiftTypes.map(shiftType => {
            return <div><Badge colorScheme={shiftType.color}>
              {shiftType.shiftName} {groupShiftTimes(shiftType.shiftTimes, t)}
            </Badge></div>;
          })
        }
      </div>
    </BorderBox>
  </div>;
};

const RightSection = (props: {position: Position}) => {
  const {position} = props;
  const {t} = useTranslation();
  const userState = useHookstate(globalUser);
  const user = userState.get()?.user;
  if (!user) return <DefaultSpinner/>
  const workerClass = getHighestWorkerClass(user, position.professionType);
  const totalPay = calculatePayForPosition(position, user);
  return <div css={css`flex: 1; display: flex; flex-direction: column; gap: 8px`}>
    <Heading fontSize={'lg'}>Greiðsla</Heading>
    <BorderBox css={css`display: flex; flex-direction: column; gap: 8px`}>
      {(Object.keys(position.shiftTypePays) as ShiftTypeName[]).map(shiftType => {
        const workerClassPays = position.shiftTypePays[shiftType] ?? {};
        const pay = findAppropriatePayment(workerClassPays, workerClass) + getSupplementalPay(user, position, shiftType);
        return <LabelInfo label={`${t(shiftType)}`}>
          {formatCurrency(pay)}
        </LabelInfo>;
      })}
      {!position.isCollectiveAgreementBased &&
          <><LabelInfo label={'Samtals'}>{formatCurrency(totalPay)}</LabelInfo>
              <Divider/>
          </>}
      <LabelInfo label={'Vinnuveitandi greiðir tryggingar'}>
        {position.insurancePaid ? 'Já' : 'Nei'}
      </LabelInfo>
      <LabelInfo label={'Ferðakostnaður greiddur'}>
        {position.travelCostPaid ? 'Já' : 'Nei'}
      </LabelInfo>
      {position.travelCostPaid && position.travelCost ? <LabelInfo label={'Ferðakostnaður'}>{formatCurrency(position.travelCost)}</LabelInfo> : null}
      <LabelInfo label={'Ferðadagur greiddur'}>
        {position.travelDayCostPaid ? 'Já' : 'Nei'}
      </LabelInfo>
      {position.travelDayCostPaid ? <LabelInfo label={'Ferðadagur'}>{formatCurrency(position.travelDayCost)}</LabelInfo> : null}
      <LabelInfo label={'Húsnæðisleiga greidd'}>
        {position.accommodationCostPaid ? 'Já' : 'Nei'}
      </LabelInfo>
      {position.accommodationCostPaid && position.accommodationCost ? <LabelInfo label={'Húsnæðisleiga'}>{formatCurrency(position.accommodationCost)}</LabelInfo> : null}
      <Divider/>
      {!props.position.isCollectiveAgreementBased ? <LabelInfo label={'Heildargreiðsla'}>
        {formatCurrency(totalPay + position.travelCost + position.travelDayCost - position.accommodationCost * position.shifts.length)}
      </LabelInfo> : <LabelInfo label={'Greiðsla'}>Samkvæmt launaflokki</LabelInfo>}
    </BorderBox>
  </div>;
};

function ApplyModal(props: { open: State<boolean>, position: State<Position | null> }) {
  const {open} = props;
  const positionState = useHookstate(props.position)
  const position = cloneState(positionState)
  if (!position) {
    throw new Error('Position is null');
  }
  const onClose = () => open.set(false);
  const positionId = position._id!!;
  const begin = position.beginDate!!;
  const end = position.endDate!!;
  const username = getUsername();
  const existingPositionRequest = position.userRequest;
  const positionRequestState = useHookstate(existingPositionRequest ?
    existingPositionRequest.request : createBlankPositionRequest(username, positionId, begin, end))
  const positionRequest = positionRequestState.get();

  const positionAccepted = position.acceptedBy === getUsername();
  const submitError = useHookstate(null as string | null);
  return <Modal isOpen={open.get()} onClose={onClose}>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>{existingPositionRequest ? 'Breyta umsókn' : 'Sækja um'}</ModalHeader>
      <ModalCloseButton/>
      <ModalBody>
        <Textarea placeholder={'Auka skilaboð'} value={positionRequest.message} onChange={
          e => positionRequestState.message.set(e.target.value)
        }/>
      </ModalBody>
      <ModalBody css={css`display: flex; flex-direction: column; gap: 8px`}>
        {!position.isCollectiveAgreementBased && <><Heading fontSize={'lg'}>Vaktir</Heading>
            <div css={css`display: flex;
              gap: 8px;
              flex-wrap: wrap`}>
              {_.range(0, differenceInDays(end, begin) + 1)
                .map((i) => addDays(begin, i))
                .map((date) => <Checkbox isChecked={
                  positionRequest.shifts.find(shift => isSameDay(shift, date)) !== undefined
                } key={date.toDateString()}
                  onChange={(e) => {
                    if (e.target.checked) {
                      positionRequestState.shifts.merge([date]);
                    } else {
                      positionRequestState.shifts.set(positionRequest.shifts.filter(d => !isSameDay(d, date)));
                    }
                  }}
                >
                  {format(date, DATE_FMT)}
                </Checkbox>)}
            </div>
        </>
        }
      <div css={css`display: flex;
            flex-direction: column;
            gap: 4px`}>
        <Heading fontSize={'lg'}>Greiðsluupplýsingar</Heading>
        <PaymentInfoSelect accountNumberState={positionRequestState.accountNumber}/>
      </div>
      </ModalBody>
      <ModalFooter css={css`display: flex; gap: 4px`}>
        <AlertDisplay error={submitError}/>
        {!existingPositionRequest ? <GoButton colorScheme={'green'} onClick={async () => {
          await createPositionRequest(positionRequest);
          positionState.set(await getPositionById(positionId));
          onClose()
        }} submitError={submitError}>Sækja um</GoButton> : <GoButton colorScheme={'green'} leftIcon={<CheckIcon/>} onClick={async () => {
          await updatePositionRequest(existingPositionRequest._id, positionRequest);
          positionState.set(await getPositionById(positionId));
          onClose()
        }} isDisabled={positionAccepted} submitError={submitError}>
          Breyta umsókn
        </GoButton>}
        {existingPositionRequest && <Button leftIcon={<BiTrash/>} colorScheme={'red'} onClick={async () => {
          if (window.confirm("Ertu viss um að þú viljir eyða umsókninni?")) {
            await deletePositionRequest(existingPositionRequest._id);
            positionState.set(await getPositionById(positionId));
            positionRequestState.set(createBlankPositionRequest(username, positionId, begin, end));
            onClose()
          }
        }} isDisabled={positionAccepted}>Eyða umsókn</Button>}
      </ModalFooter>
    </ModalContent>
  </Modal>
}

function PaymentInfoSelect(props: { accountNumberState: State<string> }) {
  const state = useHookstate(props.accountNumberState);
  const [paymentInfos, setPaymentInfos] = useState(null as PaymentInfo[] | null);
  useEffect(() => {
    (async () => {
      const infos = await getUserPaymentInfos(getUsername())
      setPaymentInfos(infos);
      if (infos.length > 0 && state.get() === '') {
        state.set(infos[0].account);
      }
    })()
  }, []);
  if (!paymentInfos) {
    return <DefaultSpinner/>
  }
  if (paymentInfos.length === 0) {
    return <Text>Þú hefur ekki skráð neinar reikningsupplýsingar</Text>
  }
  return <Select value={state.get()} onChange={e => state.set(e.target.value)}>
    {paymentInfos.map(info => <option value={info.account}>{info.name}</option>)}
  </Select>
}


function createBlankPositionRequest(
  username: string,
  positionId: string,
  beginDate: Date,
  endDate: Date,
): CreatePositionRequest {
  const dates = _.range(0, differenceInDays(endDate, beginDate) + 1)
    .map((i) => addDays(beginDate, i));
  return {
    positionId: positionId,
    shifts: dates,
    message: '',
    accountNumber: ''
  }
}